import React from 'react'
import ContentBlock from 'components/contentBlock'
import Layout from 'components/layout'
import { graphql } from 'gatsby'

const Page = ({ data }) => {
  const contentBlocks = data.thisPage.contentBlocks
  return (
    <Layout>
      <div id="content">
        {contentBlocks.map((block, i) => (
          <ContentBlock key={i} order={i} block={block} data={data} />
        ))}
      </div>
    </Layout>
  )
}
export default Page

export const collectionsPageQuery = graphql`
  query {
    site {
      siteMetadata {
        locale
      }
    }
    translations: allContentfulStringTranslations {
      edges {
        node {
          code
          en
          zh
        }
      }
    }
    thisPage: contentfulPages(slug: { eq: "collections-landing" }) {
      title
      slug
      contentBlocks {
        id
        slug
        template
        label
        title
        subtitle
        textPosition
        content {
          id
          childMarkdownRemark {
            html
          }
        }
        imagesFull {
          ...GatsbyImage
        }
        imagesFullMobile {
          ...GatsbyImage
        }
        imagesFullLink
        buttonLink
        imageSingle {
          ...GatsbyImage
        }
        video {
          wistiaCode
          optimizedFiles {
            file {
              url
              fileName
            }
          }
        }
        slides {
          images {
            ...GatsbyImage
          }
          caption
        }
      }
    }
    designers: allContentfulDesigners(
      sort: { fields: [order], order: ASC }
      limit: 3
    ) {
      edges {
        node {
          title
          slug
          image {
            ...GatsbyImage
          }
        }
      }
    }
    news: allContentfulNews(
      sort: { fields: [datePosted], order: DESC }
      limit: 8
    ) {
      edges {
        node {
          datePosted
          slug
          title
          image {
            ...GatsbyImage
          }
        }
      }
    }
    projects: allContentfulProjects(
      sort: { fields: [datePosted], order: DESC }
    ) {
      edges {
        node {
          isFeatured
          datePosted
          slug
          title
          location {
            title
          }
          image {
            ...GatsbyImage
          }
        }
      }
    }
    featuredCollections: allContentfulCollections(
      filter: { isFeatured: { eq: true } }
      sort: { fields: [order], order: ASC }
    ) {
      edges {
        node {
          slug
          title
          imageThumb {
            ...GatsbyImage
          }
          designer {
            title
            slug
          }
        }
      }
    }
    featuredProducts: allContentfulProducts(
      filter: { isFeatured: { eq: true } }
      sort: { fields: [datePosted], order: DESC }
    ) {
      edges {
        node {
          slug
          title
          imageThumb {
            ...GatsbyImage
          }
          collection {
            designer {
              title
              slug
            }
          }
        }
      }
    }
  }
`
